import axios from "axios";
import moment from "moment-timezone";
import { storageService } from "./storage.service";
import { Order } from "../pages/LocateTickets/core/_models";

export function debounce(callback: Function, wait: number) {
    let timeoutId: number | undefined;
    return (...args: any) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}

export function interceptSessionCheck() {
    console.log('interceptSessionCheck  ');
    axios.interceptors.response.use(async (response: any) => {
        console.log('test  ', response);
        if (response.status === 401) {
            alert("You are not authorized");
        }
        return response;
    }, (error) => {
        console.log('error  ', error);
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    });
}

export function userSessionIsBlocked() {
    const isLoggedIn = storageService.getFromLocalStorage('loggedinUser');
    if (isLoggedIn) {
        const userSession = JSON.parse(isLoggedIn);
        const userTime = new Date(userSession?.last_login_date);
        const diff = (new Date).getTime() - userTime.getTime();
        return userSession?.block_user === "Y" && diff < 30;
    }
    return false;
}

export function userLoginActive() {
    const isLoggedIn = storageService.getFromLocalStorage('loggedinUser');
    if (isLoggedIn) {
        const userSession = JSON.parse(isLoggedIn);
        const userTime = new Date(userSession?.last_login_date);
        const diff = Math.abs(Math.round(((new Date).getTime() - userTime.getTime()) / 60000));
        // if(diff > 15){
        //     storageService.removeFromLocalStorage('loggedinUser');
        //     storageService.removeFromLocalStorage('refresh_token');
        //     return  false;
        // }
        return userSession?.block_user === "N" && userSession?.status_code === 0;
    }
    return false;
}

export function getLoggedInNumber() {
    if (userLoginActive()) {
        const isLoggedIn = storageService.getFromLocalStorage('loggedinUser');
        const userSession = JSON.parse(isLoggedIn);
        return userSession?.phone_number;
    }
    return null;
}

export function objectIsEmpty(objectName: any): boolean {
    return JSON.stringify(objectName) === "{}";
};

export function isSafari() {
    return (
        navigator.userAgent.indexOf("Safari") > -1 &&
        navigator.userAgent.indexOf("Chrome") <= -1
    );
}

export function addYears(date: Date, years: number) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(date.getFullYear() + years);
    return dateCopy;
}

export function orderDateIsPast(
    executionDate: string,
    executionTime: string,
    timeZone: string
): boolean {
    executionTime = executionTime === null ? '23:59' : executionTime;
    timeZone = timeZone === null ? "Asia/Jerusalem" : timeZone;
    const executionDateTime = moment.tz(
        `${executionDate} ${executionTime}`,
        "YYYY-MM-DD HH:mm",
        timeZone
    );
    const today = moment.tz(timeZone);
    return today.isAfter(executionDateTime);
}

export function isOrderXS(itineraryType: string): boolean {
    if (itineraryType === "SOCCER" || itineraryType === "TENNIS" || itineraryType === "FORMULA1") {
        return true;
    }

    return false;
}

export function isOrderTixstock(itineraryType: string): boolean {
    return itineraryType === "LIVE_EVENT";
}

export function isOrderClickable(order: Order) {
    const { itinerary_type_id, order_status_id, image_clickable } = order;
    if ((isOrderTixstock(itinerary_type_id) || isOrderXS(itinerary_type_id)) && order_status_id === 8 && image_clickable === "Y") {
        return true;
    }

    if (isOrderXS(itinerary_type_id) && order_status_id === 10 && image_clickable === "Y") {
        return true;
    }

    if ((!isOrderXS(itinerary_type_id) && itinerary_type_id !== "MONETARY_CODE") && order_status_id === 10) {
        return true;
    }

    return false;
};
