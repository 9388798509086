import { storageService } from "./storage.service";

export enum ExternalParams {
  UserToken = "token",
  Sid = "sid",
  LanguageId = "language_id",
  UserName = "username",
  UtmId = "utm_id",
  UtmSource = "utm_source",
  UtmMedium = "utm_medium",
  UtmCampaign = "utm_campaign",
  UtmTerm = "utm_term",
  UtmContent = "utm_content",
  Utms = "utms",
  Product = "product",
  Voucher = "voucher",
  SoccerMatch = "soccerMatch",
  Destination = "destination",
  Event = "event",
  SportType = "sport-type",
  SportMatch = "sport-match",
  Artist = "artist",
  Concert = "concert",
  Vouchers = "vouchers",
}

const setExternalToken = (token: string) =>
  storageService.setExternalToken(token);
const getExternalToken = () => storageService.getExternalToken();
const removeExternalToken = () => storageService.removeExternalToken();

const setSidParam = (sid: string) => storageService.setSidParam(sid);
const getSidParam = () => storageService.getSidParam();
const removeSidParam = () => storageService.removeSidParam();

const setAuth = (authData: any) => storageService.setAuthData(authData);
const getAuth = () => storageService.getAuthData();
const removeAuth = () => storageService.removeAuthData();

const setLanguageId = (languageId: string) =>
  storageService.setLanguageId(languageId);
const getLanguageId = () => storageService.getLanguageId();
const removeLanguageId = () => storageService.removeLanguageId();

const setUtms = (utms: any) => storageService.setUtms(utms);
const getUtms = () => storageService.getUtms();
const removeUtms = () => storageService.removeUtms();

const externalParamsService = {
  setExternalToken,
  getExternalToken,
  removeExternalToken,
  setLanguageId,
  getLanguageId,
  removeLanguageId,
  setSidParam,
  getSidParam,
  removeSidParam,
  setAuth,
  getAuth,
  removeAuth,
  setUtms,
  getUtms,
  removeUtms,
};

export default externalParamsService;
