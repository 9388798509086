import './OtpForm.scss'
import React, { useRef, useState } from 'react';
import {Formik} from 'formik';
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {AreaCodes} from "../../../../features/areaCodes";
import {CountryCodes} from "../../../../features/countryCodes";
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import {authService} from "../../../../services/auth.service";
import clsx from "clsx";
import {OtpResponse, PhoneNumber} from "../../core/_models";
import {CustomerServiceButton} from "../../../../components/CustomerServiceBtn/CustomerServiceButton";

interface Props {
    handleOtp: (authSuccess: OtpResponse) => void;
    handlePhoneNumber: (props: PhoneNumber) => void;
    handleApiError: (value: boolean) => void;
}

const OtpForm = ({handleOtp, handlePhoneNumber,handleApiError}: Props) => {
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const [hasError, setHasError] = useState(false);
    const [errMsg, setErrorMsg] = useState('');
    const { t } = useTranslation();

    const LoginSchema = Yup.object().shape({
        country_area_code: Yup.string()
            .required(t("t:INPUT_ERRORS.REQUIRED")),
        area_code: Yup.string()
            .required(t("t:INPUT_ERRORS.REQUIRED")),
        phone_number: Yup.string().typeError(t("t:INPUT_ERRORS.MUST_BE_NUMBER"))
            .required(t("t:INPUT_ERRORS.REQUIRED"))
            .test('len', t("t:INPUT_ERRORS.PHONE_NUMBER_LEN"), val => val && val.toString().length === 7)
    });

    return (
      <>
        <Formik
          initialValues={{
            country_area_code: "+972",
            area_code: "050",
            phone_number: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(async () => {
              try {
                const recaptcha_token =
                  await reCaptchaRef?.current?.executeAsync();
                handlePhoneNumber(values);
                if (!recaptcha_token) {
                  setSubmitting(false);
                  setHasError(true);
                  setErrorMsg(t("t:ERROR.SYSTEM_FAILED_TO_REACH_DESTINATION"));
                  return;
                }
                const otpResult = await authService.getOtp(
                  values,
                  recaptcha_token
                );
                handleOtp({
                  success: otpResult.status_code === 0,
                  showResult: true,
                  status_code: otpResult.status_code,
                  block_user: otpResult.block_user,
                  status_msg: otpResult.status_msg,
                });
                //reset recaptcha
                reCaptchaRef?.current?.reset();
                setSubmitting(false);
              } catch (error: any) {
                reCaptchaRef?.current?.reset();
                setSubmitting(false);
                handleApiError(true);
              }
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ReCAPTCHA
                style={{ opacity: "0.6" }}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
                size="invisible"
                // hl={selectedLang}
                ref={reCaptchaRef}
              />
              <Row>
                <Card.Title>{t("t:LOCATE_TICKETS.AUTH_TITLE")}</Card.Title>
                <Card.Text>{t("t:LOCATE_TICKETS.AUTH_SUBTITLE")}</Card.Text>
                <Row className={clsx('p-0 mx-0 inputs-container')}>
                  <Col className="p-0 col-3">
                    <Form.Select
                      name="country_area_code"
                      aria-label={t("t:OTP_FORM.COUNTRY_CODE_ARIA_LABEL")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country_area_code}
                    >
                      {CountryCodes.map((item: any, idx: any) => {
                        return (
                          <option value={item.code} key={idx}>
                            {"‎\u200e" + item.code}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <span className="error-msg text-danger">
                      {errors.country_area_code &&
                        touched.country_area_code &&
                        errors.country_area_code}
                    </span>
                  </Col>
                  <Col className="p-0 col-3">
                    <Form.Select
                      name="area_code"
                      aria-label={t("t:OTP_FORM.AREA_CODE_ARIA_LABEL")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.area_code}
                    >
                      {AreaCodes.map((item: any, idx: any) => {
                        return (
                          <option value={item.code} key={idx}>
                            {"‎\u200e" + item.code}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <span className="error-msg text-danger">
                      {errors.area_code &&
                        touched.area_code &&
                        errors.area_code}
                    </span>
                  </Col>
                  <Col className="p-0 col-4 phone-number">
                    <input
                      type="tel"
                      name="phone_number"
                      aria-label={t("t:OTP_FORM.PHONE_NUMBER_ARIA_LABEL")}
                      onChange={(e: React.ChangeEvent<any>) => {
                        const { value } = e.target;
                        if (value && !/^\d+$/g.test(value)) return;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.phone_number}
                      className={clsx(`form-control`, {
                        "is-invalid":
                          touched.phone_number && errors.phone_number,
                      })}
                    />
                    <span className="error-msg text-danger">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span>
                  </Col>
                </Row>
                {/* <Row className="text-start justify-content-center p-0 mx-0">
                  <Col className="col-10 p-0">
                    <span className="error-msg text-danger">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span>
                  </Col>
                </Row> */}
                <Row className="my-3 justify-content-center p-0 mx-0">
                  <Col className="col-10 p-0">
                    <Button
                      type="submit"
                      className="w-100 py-2 d-flex justify-content-center align-items-center"
                      aria-label={t("t:OTP_FORM.SUBMIT_BUTTON_ARIA_LABEL")}
                      aria-describedby={t(
                        "t:OTP_FORM.SUBMIT_BUTTON_ARIA_DESCRIPTION"
                      )}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          role="status"
                        />
                      ) : (
                        t("t:LOCATE_TICKETS.SEND_CODE_BTN")
                      )}
                    </Button>
                  </Col>
                </Row>

                {hasError && (
                  <Row className="error-msg text-danger text-center">
                    <span className="my-2">{errMsg}</span>
                    <CustomerServiceButton />
                  </Row>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
}

export default OtpForm;