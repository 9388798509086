enum StorageKeys {
    RefreshToken = 'refresh_token',
    LanguageId = 'language_id',
    MemberToken = 'member_token',
    ExternalMemberToken = 'external_member_token',
    ExternalToken = 'token',
    SidParam = 'sid',
    AppId = 'app_id',
    Auth = 'auth',
    Utms = 'utms',
}

// Refresh token
function getRefreshToken() {
    return JSON.parse(getFromLocalStorage(StorageKeys.RefreshToken));
}

function setRefreshToken(value: string) {
    return setToLocalStorage(StorageKeys.RefreshToken, value);
}

// Member token
function getMemberToken() {
    return getFromLocalStorage(StorageKeys.MemberToken);
}

function setMemberToken(value: string) {
    return setToLocalStorage(StorageKeys.MemberToken, value);
}

function getExternalMemberToken() {
    return getFromLocalStorage(StorageKeys.ExternalMemberToken);
}

function setExternalMemberToken(value: string) {
    return setToLocalStorage(StorageKeys.ExternalMemberToken, value);
}

// App id
function getAppId() {
    return getFromLocalStorage(StorageKeys.AppId);
}

function setAppId(value: string) {
    setToLocalStorage(StorageKeys.AppId, value);
}

// Language id
function getLanguageId() {
    return getFromSessionStorage(StorageKeys.LanguageId);
}

function setLanguageId(value: string) {
    setToSessionStorage(StorageKeys.LanguageId, value);
}

function removeLanguageId() {
    removeFromSessionStorage(StorageKeys.LanguageId);
}

// Utils
function getFromLocalStorage(path: string) {
    return localStorage.getItem(path) || null;
}

function removeFromLocalStorage(path: string) {
    localStorage.removeItem(path);
    return true;
}

function setToLocalStorage(path: string, value: string) {
    return localStorage.setItem(path, JSON.stringify(value));
}

// Token
function getExternalToken() {
    return getFromSessionStorage(StorageKeys.ExternalToken);
}

function setExternalToken(value: string) {
    return setToSessionStorage(StorageKeys.ExternalToken, value);
}

function removeExternalToken() {
    return removeFromSessionStorage(StorageKeys.ExternalToken);
}

// Sid
function getSidParam() {
    return getFromSessionStorage(StorageKeys.SidParam);
}

function setSidParam(value: string) {
    return setToSessionStorage(StorageKeys.SidParam, value);
}

function removeSidParam() {
    return removeFromSessionStorage(StorageKeys.SidParam);
}

// Auth
function getAuthData() {
    return getFromSessionStorage(StorageKeys.Auth);
}

function setAuthData(value: any) {
    return setToSessionStorage(StorageKeys.Auth, value);
}

function removeAuthData() {
    return removeFromSessionStorage(StorageKeys.Auth);
}

// Utm
function getUtms() {
    return getFromSessionStorage(StorageKeys.Utms);
}

function setUtms(value: any) {
    return setToSessionStorage(StorageKeys.Utms, value);
}

function removeUtms() {
    return removeFromSessionStorage(StorageKeys.Utms);
}

// Session Utils
function setToSessionStorage(path: string, value: string | any) {
    return sessionStorage.setItem(path, JSON.stringify(value));
}

function getFromSessionStorage(path: string) {
    return JSON.parse(sessionStorage.getItem(path) || "null");
}

function removeFromSessionStorage(path: string) {
    sessionStorage.removeItem(path);
    return true;
}

export const storageService = {
    getRefreshToken,
    setRefreshToken,
    setToLocalStorage,
    removeFromLocalStorage,
    getFromLocalStorage,
    setToSessionStorage,
    getFromSessionStorage,
    removeFromSessionStorage,
    getExternalToken,
    setExternalToken,
    removeExternalToken,
    getSidParam,
    setSidParam,
    removeSidParam,
    getAuthData,
    setAuthData,
    removeAuthData,
    getMemberToken,
    setMemberToken,
    setExternalMemberToken,
    getExternalMemberToken,
    setAppId,
    getAppId,
    setLanguageId,
    getLanguageId,
    removeLanguageId,
    getUtms,
    setUtms,
    removeUtms
}