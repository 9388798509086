import { ExternalParams } from "../services/external-params.service";
import { objectIsEmpty } from "../services/utils.service";

export class UrlUtils {
  public static getQueryParam(paramName): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  public static getUTMQueryParam(): any[] {
    const utms = [];
    const urlParams = new URLSearchParams(window.location.search);
    Array.from(urlParams).forEach((val) => {
      if (val[0].startsWith("utm_")) {
        utms.push({ key: val[0], value: val[1] });
      }
    });
    return utms;
  }

  public static mapUTMQueryToParams() {
    const params: any = {};
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(ExternalParams.UtmId)) {
      params[ExternalParams.UtmId] = urlParams.get(ExternalParams.UtmId);
    }
    if (urlParams.has(ExternalParams.UtmSource)) {
      params[ExternalParams.UtmSource] = urlParams.get(ExternalParams.UtmSource);
    }
    if (urlParams.has(ExternalParams.UtmMedium)) {
      params[ExternalParams.UtmMedium] = urlParams.get(ExternalParams.UtmMedium);
    }
    if (urlParams.has(ExternalParams.UtmCampaign)) {
      params[ExternalParams.UtmCampaign] = urlParams.get(ExternalParams.UtmCampaign);
    }
    if (urlParams.has(ExternalParams.UtmTerm)) {
      params[ExternalParams.UtmTerm] = urlParams.get(ExternalParams.UtmTerm);
    }
    if (urlParams.has(ExternalParams.UtmContent)) {
      params[ExternalParams.UtmContent] = urlParams.get(ExternalParams.UtmContent);
    }
    
    return objectIsEmpty(params) ? null : params;
  }

  public static mapQueryToParams() {
    const params: any = {};
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(ExternalParams.Sid)) {
      params[ExternalParams.Sid] = urlParams.get(ExternalParams.Sid);
    }
    if (urlParams.has(ExternalParams.Product)) {
      params[ExternalParams.Product] = urlParams.get(ExternalParams.Product);
    }
    if (urlParams.has(ExternalParams.Voucher)) {
      params[ExternalParams.Voucher] = urlParams.get(ExternalParams.Voucher);
    }
    if (urlParams.has(ExternalParams.SoccerMatch)) {
      params[ExternalParams.SoccerMatch] = urlParams.get(ExternalParams.SoccerMatch);
    }
    if (urlParams.has(ExternalParams.Destination)) {
      params[ExternalParams.Destination] = urlParams.get(ExternalParams.Destination);
    }
    if (urlParams.has(ExternalParams.Event)) {
      params[ExternalParams.Event] = urlParams.get(ExternalParams.Event);
    }
    if (urlParams.has(ExternalParams.SportType)) {
      params[ExternalParams.SportType] = urlParams.get(ExternalParams.SportType);
    }
    if (urlParams.has(ExternalParams.SportMatch)) {
      params[ExternalParams.SportMatch] = urlParams.get(ExternalParams.SportMatch);
    }
    if (urlParams.has(ExternalParams.Artist)) {
      params[ExternalParams.Artist] = urlParams.get(ExternalParams.Artist);
    }
    if (urlParams.has(ExternalParams.Concert)) {
      params[ExternalParams.Concert] = urlParams.get(ExternalParams.Concert);
    }
    if (urlParams.has(ExternalParams.Vouchers)) {
      params[ExternalParams.Vouchers] = urlParams.get(ExternalParams.Vouchers);
    }
    
    return objectIsEmpty(params) ? null : params;
  }
}